import "./loader.css";

export const Loader = ({ className }) => {
  return (
    <div className="!absolute !w-[100vw] z-40 bg-white bg-opacity-10  !h-[100%] flex justify-center ">
      <div
        id="page"
        className="!sticky bg-blue-300 !top-[30vh] h-48 w-48 !text-red-600 !rounded-full !bg-opacity-60"
      >
        <div id="container">
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="ring"></div>
          <div id="h3" className="!text-2xl !text-red-500 !font-bold">
            Loading
          </div>
        </div>
      </div>
    </div>
  );
};
