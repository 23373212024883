import React, { useState } from "react";
import ContactUsModal from "../Pages/ContactModal";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

const Layout = ({ children }) => {
  // const [open, setOpen] = useState(true);
  return (
    <div>
      <Header />
      {children}
      {/* <LazyLoadImage
        src="https://aaraerp.s3.ap-south-1.amazonaws.com/1713432389409/SubFooter.webp"
        alt=""
        onClick={() => setOpen(true)}
      /> */}
      <Footer />
      {/* <ContactUsModal isAutoOpen={false} open={open} setOpen={setOpen} /> */}
    </div>
  );
};

export default Layout;
