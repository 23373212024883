import React, { useState } from "react";
import aaragloblogo from "../../Assets/aaraglobal-2.png";
import {
  Close,
  ExpandMore,
  Forward,
  Menu as ManuIcon,
  Search,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = useState(false);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const opens2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <>
      <div className="hidden lg:flex justify-center fixed h-20 bg-white text-black shadow-md z-50 w-full px-[15%]">
        <div className="flex font-bold text-[#343a40] pl-40 text-sm items-center relative gap-7 justify-between">
          <div className="flex items-center gap-8">
            <Link to={"/"}>
              <button className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap">
                Home
              </button>
            </Link>
            <Link to={"/about_us"}>
              <button className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap">
                About Us
              </button>
            </Link>

            <div className="relative">
              <div
                className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap capitalize cursor-pointer"
                onClick={handleClick}
              >
                Services
              </div>
              <Menu
                anchorEl={anchorEl}
                open={opens}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {[
                  {
                    to: "/search_engine_optimization",
                    text: "Search Engine Optimization",
                  },
                  {
                    to: "/social_media_marketing",
                    text: "Social Media Marketing",
                  },
                  {
                    to: "/social_engine_marketing",
                    text: "Social Engine Marketing",
                  },
                  { to: "/pay_per_click", text: "Pay Per Click" },
                  { to: "/email_marketing", text: "Email Marketing" },
                  { to: "/content_marketing", text: "Content Marketing" },
                  {
                    to: "/exhibition_and_signge_design",
                    text: "Exhibition & Signage Design",
                  },
                  {
                    to: "/graphic_and_print_desion",
                    text: "Graphic & Print Design",
                  },
                  { to: "/social_media_design", text: "Social Media Design" },
                  {
                    to: "/email_template_desion",
                    text: "Email Template Design",
                  },
                  {
                    to: "/3D_modeling_and_design",
                    text: "3D Modeling & Design",
                  },
                  {
                    to: "/promo_video_marketing",
                    text: "Promo Video Production & Design",
                  },
                ].map(({ to, text }) => (
                  <Link key={to} to={to}>
                    <MenuItem className="!text-sm" onClick={handleClose}>
                      {text}
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </div>

            <div className="relative">
              <div
                className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap capitalize cursor-pointer"
                onClick={handleClick2}
              >
                Tools
              </div>
              <Menu
                anchorEl={anchorEl2}
                open={opens2}
                onClose={handleClose2}
                TransitionComponent={Fade}
              >
                {[
                  { to: "/site_exp", text: "Site Explorer" },
                  { to: "/keywords_exp", text: "Keywords Explorer" },
                  { to: "/site_audit", text: "Site Audit" },
                  { to: "/rank_tracker", text: "Rank Tracker" },
                  { to: "/content_explore", text: "Content Explorer" },
                ].map(({ to, text }) => (
                  <Link key={to} to={to}>
                    <MenuItem className="!text-sm" onClick={handleClose2}>
                      {text}
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </div>

            <Link to={"/blogs"}>
              <button className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap">
                Blog
              </button>
            </Link>
            <Link to={"/faq"}>
              <button className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap">
                FAQ
              </button>
            </Link>
            <Link to={"/contact_us"}>
              <button className="hover:text-[#ff681c] text-lg text-black font-semibold whitespace-nowrap">
                Contact
              </button>
            </Link>
            <Link to={"/contact_us"} target="_blank">
              <button className="border hover:bg-[#44474b] text-sm text-black font-semibold whitespace-nowrap hover:text-white bg-[#ededed] p-1 px-2 h-fit rounded-full flex items-center">
                Get Quote Now
                <IconButton>
                  <Forward className="!border !rounded-full !bg-[#d6d6d6] !p-1 text-black" />
                </IconButton>
              </button>
            </Link>
          </div>

          <div className="absolute lg:h-44 -top-4 lg:-left-16 xl:h-56 xl:-left-40">
            <Link to={"/"}>
              <LazyLoadImage
                src={aaragloblogo}
                alt=""
                className="size-60 animate-pulse h-full object-contain"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <div className="flex w-full bg-[#1c2330] justify-between items-center p-3">
          <Link to={"/"}>
            <LazyLoadImage src={aaragloblogo} alt="Logo" className="h-10" />
          </Link>
          <IconButton onClick={() => setOpen(true)}>
            <ManuIcon className="!text-white" />
          </IconButton>
        </div>

        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          className="h-screen"
          PaperProps={{
            sx: {
              width: {
                xs: "80%",
                sm: "70%",
                md: "50%",
                lg: "40%",
              },
              maxWidth: "100%",
            },
          }}
        >
          <List>
            <ListItem className="!flex !justify-between gap-3 p-2">
              <div className="flex flex-grow border rounded-xl p-1 bg-white">
                <input
                  type="search"
                  placeholder="Search Something..."
                  className="outline-none w-full px-2"
                />
                <IconButton>
                  <Search />
                </IconButton>
              </div>
              <IconButton onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </ListItem>

            <Divider />

            <div className="flex flex-col w-full gap-2 p-2">
              <Link to={"/"}>
                <ListItemButton
                  onClick={() => setOpen(false)}
                  className="!bg-[#327e9c] !h-12 !rounded-xl !text-white"
                >
                  Home
                </ListItemButton>
              </Link>
              <Link to={"/about_us"}>
                <ListItemButton
                  onClick={() => setOpen(false)}
                  className="!bg-[#327e9c] !h-12 !rounded-xl !text-white"
                >
                  About Us
                </ListItemButton>
              </Link>
              <Accordion className="!bg-[#327e9c] !rounded-xl !text-white">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <p>Service</p>
                </AccordionSummary>
                <AccordionDetails>
                  <span className="flex flex-col gap-4">
                    {[
                      {
                        path: "/search_engine_optimization",
                        label: "Search Engine Optimization (SEO)",
                      },
                      {
                        path: "/social_media_marketing",
                        label: "Social Media Marketing (SMM)",
                      },
                      {
                        path: "/social_engine_marketing",
                        label: "Search Engine Marketing (SEM)",
                      },
                      {
                        path: "/pay_per_click",
                        label: "Pay Per Click (PPC) Campaign",
                      },
                      { path: "/email_marketing", label: "Email Marketing" },
                      {
                        path: "/content_marketing",
                        label: "Content Marketing",
                      },
                      {
                        path: "/exhibition_and_signge_design",
                        label: "Exhibition & Signage Design Services",
                      },
                      {
                        path: "/graphic_and_print_desion",
                        label: "Graphic & Print Design",
                      },
                      {
                        path: "/social_media_design",
                        label: "Social Media Design",
                      },
                      {
                        path: "/email_template_desion",
                        label: "Email Template Design",
                      },
                      {
                        path: "/3D_modeling_and_design",
                        label: "3D Modeling & Design",
                      },
                      {
                        path: "/promo_video_marketing",
                        label: "Promo Video Production & Design",
                      },
                    ].map((item) => (
                      <Link key={item.path} to={item.path}>
                        <p onClick={() => setOpen(false)}>{item.label}</p>
                      </Link>
                    ))}
                  </span>
                </AccordionDetails>
              </Accordion>
              <Accordion className="!bg-[#327e9c] !rounded-xl !text-white">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <p>Tools</p>
                </AccordionSummary>
                <AccordionDetails>
                  <span className="flex flex-col gap-4">
                    {[
                      { path: "/site_exp", label: "Site Explorer" },
                      { path: "/keywords_exp", label: "Keywords Explorer" },
                      { path: "/site_audit", label: "Site Audit" },
                      { path: "/rank_tracker", label: "Rank Tracker" },
                      { path: "/content_explore", label: "Content Explorer" },
                    ].map((item) => (
                      <Link key={item.path} to={item.path}>
                        <p onClick={() => setOpen(false)}>{item.label}</p>
                      </Link>
                    ))}
                  </span>
                </AccordionDetails>
              </Accordion>
              <Link to={"/blogs"}>
                <ListItemButton
                  onClick={() => setOpen(false)}
                  className="!bg-[#327e9c] !h-12 !rounded-xl !text-white"
                >
                  Blog
                </ListItemButton>
              </Link>
              <Link to={"/faq"}>
                <ListItemButton
                  onClick={() => setOpen(false)}
                  className="!bg-[#327e9c] !h-12 !rounded-xl !text-white"
                >
                  FAQ
                </ListItemButton>
              </Link>
              <Link to={"/contact_us"}>
                <ListItemButton
                  onClick={() => setOpen(false)}
                  className="!bg-[#327e9c] !h-12 !rounded-xl !text-white"
                >
                  Contact
                </ListItemButton>
              </Link>
              <button
                onClick={() => setOpen(false)}
                style={{
                  background:
                    "linear-gradient(to right, rgba(102, 126, 234, 0.5), rgba(118, 75, 162, 0.5))",
                }}
                className="flex items-center gap-2 p-2 rounded-xl text-white"
              >
                Get Quote Now
                <IconButton>
                  <Forward className="!border !rounded-full !p-1" />
                </IconButton>
              </button>
            </div>
          </List>
        </Drawer>
      </div>
    </>
  );
};

export default Header;
